import React from 'react';
import BackgroundImage from './sidewalk-entrance-to-a-white-building-1790584.jpg';
import { Helmet } from 'react-helmet';
import './App.scss';

var TITLE = "MvA - Home";

const App = () => {
  
  return(
    <div className="App">
      <Helmet>
        <title>{ TITLE }</title>
      </Helmet>
      
      <div className="BackgroundImageWrapper"> 
        <img src={BackgroundImage} alt="Nice picture of a building." />
      </div>
      <div className="BlockRight">
        <div className="TitleSection">
          <h3 id="Title1">Mees Van Aarsen</h3>
          <h2 id="TitleTilde">≈</h2>
          <h1 id="Title2">Application</h1>
          <h3 id="Title3">D  e  v  e  l  o  p  e  r</h3>
        </div>
      </div>
    </div>
  );
}
export default App;
